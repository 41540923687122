.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.firework-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 200vh;
  z-index: -1;
}

.overlay-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
  padding: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
  width: 100%;
  height: calc(100vh - 40px);
  grid-template-rows: 280px 600px;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    height: calc(100vh - 40px);
    overflow-y: scroll;
  }

  .grid-item:nth-child(1) {
    order: 1;
  }

  .grid-item:nth-child(2) {
    order: 4;
  }

  .grid-item:nth-child(3) {
    order: 2;
  }

  .grid-item:nth-child(4) {
    order: 3;
  }

}

.flexbox-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.icon {
  padding: 5px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.left-column {
  padding-left: 2%;
}

.right-column {
  padding-right: 2%;
}

.spotify-container {
  padding-left: 2%;
}

.mixcloud-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 54px;
  
}

.mixcloud-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.bodacious-icon {
  padding-right: 17px;
}

.bodacious-img {
  padding-top: 5px;
  border-radius: 50%;
  width: 40px;
}

.grid-item {
  width: 90%;
  height: 100%;
  padding: 1%;
}

h1 {
  font-size: 60px;
  margin-bottom: 20px;
  letter-spacing: 10px;
}

h2 {
  font-size: 36px;
}

p {
  font-size: 24px;
}